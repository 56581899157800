<template>
  <b-modal
    id="add-course-modal"
    title="Зарегистрировать на курс"
    centered
    hide-footer
    scrollable
    no-close-on-backdrop
    size="md"
    @hidden="clearForm"
  >
    <label>Курс:</label>
    <wayup-select
      v-model="selectedCourse"
      class="select mb-1"
      placeholder="выберите курс"
      emptyFilterMessage="нет подходящих курсов"
      :options="courses"
      :clearable="false"
      label="title"
      :appendToBody="true"
      @change="onCourseChanged"
    >
      <template #option="{ title, imageUrl, hex }">
        <div class="d-flex align-items-center course-option">
          <div
            class="
              image-wrapper
              d-flex
              align-items-center
              justify-content-center
              mr-50
            "
            :style="`background: ${hex}`"
          >
            <img :src="imageUrl" alt="" />
          </div>
          <div class="title">
            {{ title }}
          </div>
        </div>
      </template>
      <template #selected-option="{ title, imageUrl, hex }">
        <div class="d-flex align-items-center course-option selected">
          <div
            class="
              image-wrapper
              d-flex
              align-items-center
              justify-content-center
              mr-50
            "
            :style="`background: ${hex}`"
          >
            <img :src="imageUrl" alt="" />
          </div>
          <div class="title">
            {{ title }}
          </div>
        </div>
      </template>
    </wayup-select>

    <label>Поток:</label>
    <wayup-select
      v-model="selectedStream"
      class="select mb-1"
      placeholder="выберите поток"
      emptyFilterMessage="нет подходящих потоков"
      :disabled="!selectedCourse"
      :closeOnSelect="true"
      :options="streams"
      :clearable="false"
      :appendToBody="true"
      label="title"
      @change="onStreamChanged"
    >
      <template #option="{ title, dateStart }">
        <div class="d-flex align-items-center option">
          <div class="title">
            <div>
              {{ title }}
            </div>
            <div class="bottom-line">
              <feather-icon size="15" icon="CalendarIcon" />
              <small>
                {{ formatDate(dateStart) }}
              </small>
            </div>
          </div>
        </div>
      </template>
    </wayup-select>

    <label>Ментор:</label>
    <wayup-select
      v-model="selectedMentor"
      class="select mb-1"
      placeholder="выберите наставника"
      emptyFilterMessage="нет подходящих наставников"
      :disabled="!selectedStream || pending"
      :closeOnSelect="true"
      :options="mentors"
      :clearable="false"
      :appendToBody="true"
      :pending="pending"
      label="name"
    >
      <template #option="{ name, avatarUrl, email }">
        <div class="d-flex align-items-center option">
          <b-avatar :src="avatarUrl" class="mr-50" />
          <div class="title">
            <div>
              {{ name }}
            </div>
            <div class="bottom-line">
              <small>
                {{ email }}
              </small>
            </div>
          </div>
        </div>
      </template>
    </wayup-select>

    <label
      v-if="selectedCourse && selectedCourse.type === 'paid'"
      class="w-100 mb-1"
    >
      Дата финального платежа:
      <flat-pickr
        v-model="finalPaymentDate"
        class="form-control"
        :config="{
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'l d M Y',
          locale: 'ru',
        }"
      />
    </label>

    <label
      v-if="selectedCourse && selectedCourse.type === 'paid'"
      class="w-100 mb-1"
    >
      Сумма платежа:
      <b-form-input
        v-model="paymentAmount"
        number
        type="number"
        placeholder="сумма платежа"
      />
    </label>

    <label
      v-if="selectedCourse && selectedCourse.type === 'paid'"
      class="w-100 mb-1"
    >
      Цена курса($):
      <b-form-input
        v-model="coursePrice"
        number
        type="number"
        placeholder="цена курса"
      />
    </label>

    <label
      v-if="selectedCourse && selectedCourse.type === 'paid'"
      class="w-100 mb-1"
    >
      Комментарий:
      <b-form-textarea v-model="comment" placeholder="комментарий" />
    </label>

    <b-button variant="primary" @click="register">Зарегистрировать</b-button>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import WayupSelect from '@/components/WayupSelect.vue'
import { showError, showMessage } from '@/helpers/notifications'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BInputGroup,
    WayupSelect,
    BAvatar,
    flatPickr,
    BFormTextarea,
    BButton,
  },
  data: () => ({
    selectedCourse: null,
    selectedStream: null,
    selectedMentor: null,
    finalPaymentDate: null,
    coursePrice: '',
    paymentAmount: '',
    comment: '',
    courses: [],
    mentors: [],
    studentId: null,
    pending: false,
  }),
  computed: {
    streams() {
      return this.selectedCourse?.streams || []
    },
  },
  async mounted() {
    await this.fetchCourses()
    await this.fetchCoursesPrices()
  },
  methods: {
    show(id) {
      this.studentId = id
      this.$bvModal.show('add-course-modal')
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('ru')
    },
    async fetchCourses() {
      try {
        const response = await this.$axios.get('v1/courses-with-streams')
        this.courses = response.data.courses
        this.selectedCourse = this.courses[0]
        this.onCourseChanged()
        this.onStreamChanged()
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при загрузке курсов')
      }
    },
    async fetchCoursesPrices() {
      try {
        const response = await this.$axios.get('v1/courses-prices')

        const prices = response.data.courses

        for (const course of this.courses) {
          const paidCourse = prices.find(c => c.id === course.id)
          if (paidCourse) {
            course.price = paidCourse.price
          }
        }
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при загрузке цен курсов')
      }
    },
    onCourseChanged() {
      let nearestStream = this.selectedCourse.streams[0]
      for (const stream of this.selectedCourse.streams) {
        if (
          new Date(stream.dateStart) - new Date(nearestStream.dateStart) < 0 &&
          new Date(stream.dateStart) - new Date() > 0
        ) {
          nearestStream = stream
        }
        this.selectedStream = nearestStream
      }

      if (this.selectedCourse.type === 'paid') {
        this.coursePrice = this.selectedCourse.price
      }

      this.fetchMentors()
    },
    async fetchMentors() {
      this.pending = true
      try {
        const response = await this.$axios.get(
          `v1/courses/${this.selectedCourse.id}/mentors`,
        )
        this.mentors = response.data.mentors
        this.selectedMentor =
          this.mentors[Math.floor(Math.random() * this.mentors.length)]
      } catch (error) {
        console.error(error)
        showError('Ошибка получения менторов')
      }
      this.pending = false
    },

    onStreamChanged() {
      if (new Date(this.selectedStream.dateStart) - new Date() > 0) {
        this.finalPaymentDate = this.selectedStream.dateStart
      } else {
        this.finalPaymentDate = new Date().toISOString().slice(0, 10)
      }
    },
    async register() {
      if (!this.validate()) {
        return
      }

      try {
        await this.$axios.post(`v1/students/${this.studentId}/courses/add`, {
          streamId: this.selectedStream.id,
          mentorId: this.selectedMentor.id,
          comment: this.comment,
          price: this.coursePrice,
          amount: this.paymentAmount,
          finalPaymentDate: this.finalPaymentDate,
        })

        showMessage('Студент успешно зарегистрирован на курс')
        this.$bvModal.hide('add-course-modal')
        this.$emit('onCourseAdded')
      } catch (error) {
        if (error.response.status === 400) {
          showError(error.response.data.message)
        } else {
          showError('При регистрации на курс произошла ошибка')
        }
      }
    },
    validate() {
      if (this.selectedCourse.type === 'free') {
        return true
      }

      let isValid = true

      if (this.finalPaymentDate === '') {
        isValid = false
        showError('Не заполнена дата платежа')
      }

      if (this.comment === '') {
        isValid = false
        showError('Не заполнен комментарий')
      }

      if (
        !this.coursePrice ||
        this.coursePrice === '' ||
        this.coursePrice <= 0
      ) {
        isValid = false
        showError('Стоимость курса должна быть больше 0')
      }

      if (
        !this.paymentAmount ||
        this.paymentAmount === '' ||
        this.paymentAmount <= 0
      ) {
        isValid = false
        showError('Сумма платежа должна быть больше 0')
      }

      if (this.coursePrice < this.paymentAmount) {
        isValid = false
        showError('Сумма платежа должна быть меньше или равна цене курса')
      }

      return isValid
    },
    clearForm() {
      this.paymentAmount = ''
      this.coursePrice = ''
      this.selectedCourse = this.courses[0]
      this.onCourseChanged()
      this.onStreamChanged()
      this.comment = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.course-option {
  padding: 5px;
  overflow: hidden;

  .title {
    overflow: hidden;
  }

  .image-wrapper {
    width: 35px;
    height: 35px;
    min-width: 35px;
    border-bottom: 1px solid $input-border-color;
    border-radius: 5px;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }

  &.selected {
    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .image-wrapper {
      width: 27px;
      height: 27px;
      min-width: 27px;
    }
  }
}

.option {
  padding: 5px;
  overflow: hidden;

  .title {
    overflow: hidden;
  }
  .bottom-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-muted;
  }
}
</style>