import WayupTable from '@/components/WayupTable'
import { CellData, Column, FetchFunction } from '@/components/WayupTable/types'
import { Vue, Component, Ref } from 'vue-property-decorator'
import axios from '@/libs/axios'
import axiosStatic, { Canceler } from 'axios'
import { AxiosResponse } from 'axios'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import { BButton } from '@/components/bootstrap'
import Link from '@/components/Link'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import { showError, showMessage } from '@/helpers/notifications'

const AddCourseModal = require('@/components/students/AddCourseModal.vue')
  .default

type Student = {
  id: number
  fullName: string
  avatarUrl: string
  email: string
  phone: string
  points: string
  registrationDate: string
}

@Component
export default class AllStudentsPage extends Vue {
  @Ref() readonly addCourseModal!: { show: (studentId: number) => void }
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal
  @Ref() readonly table!: WayupTable

  axiosCanceler: Canceler | null = null
  columns: Column<Student>[] = [
    {
      field: 'fullName',
      title: 'Имя',
      sortable: true,
    },
    {
      field: 'email',
      title: 'E-mail',
      type: 'email',
      sortable: true,
    },
    {
      field: 'phone',
      title: 'Телефон',
      sortable: true,
    },
    {
      field: 'points',
      title: 'Баллов',
      sortable: true,
      formatter: value => value || 0,
    },
    {
      field: 'registrationDate',
      title: 'Дата регистрации',
      type: 'dateTime',
      noWrap: true,
      sortable: true,
    },
    {
      field: 'id',
      title: '',
    },
  ]

  loadStudents: FetchFunction<Student> = async (
    page,
    perPage,
    search,
    sort,
  ) => {
    if (this.axiosCanceler) {
      this.axiosCanceler('cancel')
    }

    const searchQuery = search ? `&searchQuery=${search}` : ''
    const sortBy = sort
      ? `&sortBy=${sort.field}&sortDir=${sort.dir}`
      : '&sortBy=id&sortDir=asc'

    const query = `v1/students-all?page=${page}&perPage=${perPage}${searchQuery}${sortBy}`

    const cancelTokenSource = axiosStatic.CancelToken.source()
    this.axiosCanceler = cancelTokenSource.cancel

    const response: AxiosResponse<{
      students: Student[]
      studentsCount: number
    }> = await axios.get(query, { cancelToken: cancelTokenSource.token })

    this.axiosCanceler = null

    return {
      rows: response.data.students,
      totalRows: response.data.studentsCount,
    }
  }

  async deleteStudent(student: Student) {
    const result = await this.deleteConfirmationModal.show(student.fullName)

    if (result) {
      try {
        await axios.delete(`v1/students/${student.id}`)

        this.table.modifyRows((students: Student[]) => {
          return students.filter(s => s.id !== student.id)
        })

        showMessage('Студент удалён', 'Успех')
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при удалении студента')
      }
    }
  }

  cellTemplate(cellData: CellData<Student>) {
    switch (cellData.column.field) {
      case 'fullName':
        return (
          <Link to={`/students/${cellData.row.id}`}>
            <UserCell
              avatar={cellData.row.avatarUrl}
              firstRow={cellData.row.fullName}
            />
          </Link>
        )
      case 'id':
        return (
          <div class="d-flex">
            <BButton
              onClick={() => this.addCourseModal.show(cellData.row.id)}
              variant="outline-primary"
              class="text-nowrap p-50 mr-50"
            >
              Добавить курс
            </BButton>
            <BButton
              onClick={() => this.deleteStudent(cellData.row)}
              variant="outline-danger"
              class="p-50"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </BButton>
          </div>
        )
    }
  }

  protected render() {
    return (
      <div>
        <WayupTable
          ref="table"
          columns={this.columns}
          fetchFunction={this.loadStudents}
          perPage={20}
          sort={{ field: 'registrationDate', dir: 'desc' }}
          paginationEnabled
          searchEnabled
          scopedSlots={{ default: cellData => this.cellTemplate(cellData) }}
        />
        <AddCourseModal ref="addCourseModal" />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
      </div>
    )
  }
}
