var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-course-modal","title":"Зарегистрировать на курс","centered":"","hide-footer":"","scrollable":"","no-close-on-backdrop":"","size":"md"},on:{"hidden":_vm.clearForm}},[_c('label',[_vm._v("Курс:")]),_c('wayup-select',{staticClass:"select mb-1",attrs:{"placeholder":"выберите курс","emptyFilterMessage":"нет подходящих курсов","options":_vm.courses,"clearable":false,"label":"title","appendToBody":true},on:{"change":_vm.onCourseChanged},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var imageUrl = ref.imageUrl;
var hex = ref.hex;
return [_c('div',{staticClass:"d-flex align-items-center course-option"},[_c('div',{staticClass:"\n            image-wrapper\n            d-flex\n            align-items-center\n            justify-content-center\n            mr-50\n          ",style:(("background: " + hex))},[_c('img',{attrs:{"src":imageUrl,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")])])]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
var imageUrl = ref.imageUrl;
var hex = ref.hex;
return [_c('div',{staticClass:"d-flex align-items-center course-option selected"},[_c('div',{staticClass:"\n            image-wrapper\n            d-flex\n            align-items-center\n            justify-content-center\n            mr-50\n          ",style:(("background: " + hex))},[_c('img',{attrs:{"src":imageUrl,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(title)+" ")])])]}}]),model:{value:(_vm.selectedCourse),callback:function ($$v) {_vm.selectedCourse=$$v},expression:"selectedCourse"}}),_c('label',[_vm._v("Поток:")]),_c('wayup-select',{staticClass:"select mb-1",attrs:{"placeholder":"выберите поток","emptyFilterMessage":"нет подходящих потоков","disabled":!_vm.selectedCourse,"closeOnSelect":true,"options":_vm.streams,"clearable":false,"appendToBody":true,"label":"title"},on:{"change":_vm.onStreamChanged},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var dateStart = ref.dateStart;
return [_c('div',{staticClass:"d-flex align-items-center option"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v(" "+_vm._s(title)+" ")]),_c('div',{staticClass:"bottom-line"},[_c('feather-icon',{attrs:{"size":"15","icon":"CalendarIcon"}}),_c('small',[_vm._v(" "+_vm._s(_vm.formatDate(dateStart))+" ")])],1)])])]}}]),model:{value:(_vm.selectedStream),callback:function ($$v) {_vm.selectedStream=$$v},expression:"selectedStream"}}),_c('label',[_vm._v("Ментор:")]),_c('wayup-select',{staticClass:"select mb-1",attrs:{"placeholder":"выберите наставника","emptyFilterMessage":"нет подходящих наставников","disabled":!_vm.selectedStream || _vm.pending,"closeOnSelect":true,"options":_vm.mentors,"clearable":false,"appendToBody":true,"pending":_vm.pending,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var avatarUrl = ref.avatarUrl;
var email = ref.email;
return [_c('div',{staticClass:"d-flex align-items-center option"},[_c('b-avatar',{staticClass:"mr-50",attrs:{"src":avatarUrl}}),_c('div',{staticClass:"title"},[_c('div',[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"bottom-line"},[_c('small',[_vm._v(" "+_vm._s(email)+" ")])])])],1)]}}]),model:{value:(_vm.selectedMentor),callback:function ($$v) {_vm.selectedMentor=$$v},expression:"selectedMentor"}}),(_vm.selectedCourse && _vm.selectedCourse.type === 'paid')?_c('label',{staticClass:"w-100 mb-1"},[_vm._v(" Дата финального платежа: "),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'l d M Y',
        locale: 'ru',
      }},model:{value:(_vm.finalPaymentDate),callback:function ($$v) {_vm.finalPaymentDate=$$v},expression:"finalPaymentDate"}})],1):_vm._e(),(_vm.selectedCourse && _vm.selectedCourse.type === 'paid')?_c('label',{staticClass:"w-100 mb-1"},[_vm._v(" Сумма платежа: "),_c('b-form-input',{attrs:{"number":"","type":"number","placeholder":"сумма платежа"},model:{value:(_vm.paymentAmount),callback:function ($$v) {_vm.paymentAmount=$$v},expression:"paymentAmount"}})],1):_vm._e(),(_vm.selectedCourse && _vm.selectedCourse.type === 'paid')?_c('label',{staticClass:"w-100 mb-1"},[_vm._v(" Цена курса($): "),_c('b-form-input',{attrs:{"number":"","type":"number","placeholder":"цена курса"},model:{value:(_vm.coursePrice),callback:function ($$v) {_vm.coursePrice=$$v},expression:"coursePrice"}})],1):_vm._e(),(_vm.selectedCourse && _vm.selectedCourse.type === 'paid')?_c('label',{staticClass:"w-100 mb-1"},[_vm._v(" Комментарий: "),_c('b-form-textarea',{attrs:{"placeholder":"комментарий"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.register}},[_vm._v("Зарегистрировать")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }